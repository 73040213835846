
const { format, utcToZonedTime } = require('date-fns-tz');

export const formatDate = (date: Date | string | undefined) => {
  if (!date) return;
  return date instanceof Date ? dateBr(date) : dateBr(new Date(date));
};

export const dateBr = (date: Date) => {
  return `${date.getDate()}/${date.getMonth() + 1}/${date.getFullYear()}`;
};

export const hour = (date: Date) => {
	const diffTimeZoneInHours = date.getTimezoneOffset() / 60;
	return `${date.getHours() - diffTimeZoneInHours}:${
    date.getMinutes() < 10 ? `0${date.getMinutes()}` : date.getMinutes()
  }`;
};

export const formatDateHour = (date: Date | string | undefined) => {
  if (!date) return;
  const dateFormatted =
    date instanceof Date ? dateBr(date) : dateBr(new Date(date));
  const hourFormatted = date instanceof Date ? hour(date) : hour(new Date(date));
  return `${dateFormatted} ${hourFormatted}`;
};

export function formatDateTime(isoDate: Date) {
  const timeZone = 'America/Sao_Paulo';
  const zonedDate = utcToZonedTime(isoDate, timeZone);
  return format(zonedDate, "dd/MM/yyyy - HH:mm", { timeZone });
}