import {
  AppBar,
  Box,
  Button,
  // Button,
  CircularProgress,
  Divider,
  IconButton,
  MenuItem,
  Toolbar,
  Typography
} from '@material-ui/core';
import Dialog from '@material-ui/core/Dialog';
import Slide from '@material-ui/core/Slide';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import { TransitionProps } from '@material-ui/core/transitions';
import { MoreHoriz, Refresh } from '@material-ui/icons';
import CloseIcon from '@material-ui/icons/Close';
import { ModalActionConfirm } from 'components/ModalActionConfirm';
import { StatusChip } from 'components/StatusChip';
import { useApp } from 'contexts/App/appContext';
import { useFintalkCloud } from 'contexts/FintalkCloud/fintalkCloudContext';
import { useToast } from 'contexts/ToastContext';
import React, { useCallback, useEffect, useState } from 'react';
import { Trans } from 'react-i18next';
import { DeleteContentGroup, GetContentGroupList } from 'services/123FalaService';
import { ContentGroupListSchema } from 'services/123FalaService/types';
import { formatDateTime } from 'utils/Date';
import useTranslator from 'utils/hooks/Translator';
import { getTranslationKeys } from 'utils/i18n/types';
import { ModalToPopProps } from '../../BuilderModals/types';
import { ModalAddDataSource } from '../ModalAddDataSource';
import { ModalAddOrEditContentGroup } from '../ModalAddOrEditContentGroup';
import ModalDataSourceList from '../ModalDataSourceList';
import * as S from '../style';

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children?: React.ReactElement },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function ModalContentGroupList(props: ModalToPopProps) {
  const classes = S.useStyles();
  const { handleClose } = props;
  const { getTranslation } = useTranslator();
  const { dispatch } = useApp();
  const { currentData: { agentName } } = useFintalkCloud();
  const { toastNotification: toast } = useToast();


  const [isLoading, setIsLoading] = useState(false);
  const [actionsMenuAnchorEl, setActionsMenuAnchorEl] = useState<null | HTMLElement>(null);
  const [isOpenModalAddOrEditContentGroup, setIsOpenModalAddOrEditContentGroup] = useState<boolean>(false);
  const [isOpenModalDataSourceList, setIsOpenModalDataSourceList] = useState<boolean>(false);
  const [isOpenModalAddDataSource, setIsOpenModalAddDataSource] = useState<boolean>(false);
  const [isOpenModalActionConfirm, setIsOpenModalActionConfirm] = useState<boolean>(false);
  const [contentGroups, setContentGroups] = useState<ContentGroupListSchema[]>([]);
  const [contentGroupSelected, setContentGroupSelected] = useState<ContentGroupListSchema | null>(null);
  const [refresh, setRefresh] = useState<boolean>(false);

  const handleOpenActionsMenu = (
    event: React.MouseEvent<HTMLElement>,
    contentGroup: ContentGroupListSchema
  ) => {
    setActionsMenuAnchorEl(event.currentTarget);
    setContentGroupSelected(contentGroup);
  };

  const handleCloseActionsMenu = () => {
    setActionsMenuAnchorEl(null);
    setContentGroupSelected(null);
  };

  const openAddOrEditModal = () => {
    setIsOpenModalAddOrEditContentGroup(true);
  };

  const openAddModal = () => {
    setIsOpenModalAddDataSource(true);
  };

  const openViewModal = () => {
    setIsOpenModalDataSourceList(true);
  };

  const openActionConfirm = () => {
    setIsOpenModalActionConfirm(true);
  };

  const loadContentGroups = useCallback(async () => {
    try {
      setIsLoading(true);
      setActionsMenuAnchorEl(null);

      const resp = await GetContentGroupList(agentName, dispatch);

      if (resp.StatusCode < 200 || resp.StatusCode >= 300) {
        toast({ 
          type: 'warning', 
          message: getTranslation('toast.error.contentGroupsNotFound') 
        });

        return;
      }

      const data = resp.Data.data.sort((a, b) => {
        return new Date(b.updated_at).getTime() - new Date(a.updated_at).getTime()
      });

      setContentGroups(data);
    } catch (error) {
      toast({
        type: 'error',
        message: getTranslation('toast.error.error'),
      });
    } finally {
      setIsLoading(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [agentName, dispatch, refresh]);

  const deleteContentGroup = async () => {
    if (contentGroupSelected) {
      try {
        const resp = await DeleteContentGroup(agentName, contentGroupSelected.id,dispatch);
  
        if (resp.StatusCode < 200 || resp.StatusCode >= 300) {
          toast({
            type: 'warning', 
            message: getTranslation('toast.error.deleteContentGroup') 
          });
  
          return;
        }
  
        toast({
          type: 'success',
          message: getTranslation('toast.success.deleteContentGroup')
        });

        setIsOpenModalActionConfirm(false);
        loadContentGroups();
      } catch (error) {
        toast({
          type: 'error',
          message: getTranslation('toast.error.error'),
        });
      } finally {
        setIsLoading(false);
      }
    }
  }

  useEffect(() => {
    loadContentGroups();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refresh]);

  return (
    <>
      <Dialog
        open
        fullWidth={true}
        TransitionComponent={Transition}
        maxWidth="lg"
        PaperProps={{ classes: { rounded: classes.dialog } }}
      >
        <S.DialogContainer>
          <AppBar>
            <Toolbar>
              <Typography
                variant="h5"
                className={classes.title}
              >
                {getTranslation('contentGroups').toUpperCase()}
              </Typography>

              <IconButton
                edge="start"
                color="inherit"
                onClick={() => handleClose()}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Toolbar>
          </AppBar>

          {isLoading ? (
            <Box
              component='div'
              style={{
                width: '100%',
                height: 200,
                padding: 24,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              <CircularProgress color="inherit" size={56} />
            </Box>
          ) : (
            <S.ContentContainer>
              <S.ContentHeader>
                <Typography
                  variant="h6"
                >
                  <strong>{contentGroups.length}</strong> {getTranslation('contentGroups')}
                </Typography>

                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    gridGap: 16,
                  }}
                >
                  <IconButton onClick={() => loadContentGroups()}>
                    <Refresh htmlColor='#000' />
                  </IconButton>

                  <Button
                    variant="contained"
                    color="secondary"
                    className={classes.addButton}
                    onClick={() => {
                      setIsOpenModalAddOrEditContentGroup(true);
                      setContentGroupSelected(null);
                    }}
                  >
                    {getTranslation('modal.123Fala.addContentGroup')}
                  </Button>
                </Box>
              </S.ContentHeader>

              {contentGroups.length < 1 ? (
                <Typography
                  variant='subtitle1'
                  style={{
                    fontSize: 16,
                    textAlign: 'center',
                    paddingTop: 32,
                  }}
                >
                  {getTranslation('noContentGroups')}...
                </Typography>
              ) : (
                <TableContainer style={{ height: '60vh', maxHeight: '800px' }}>
                  <Table
                    className={classes.table}
                    aria-label="simple table"
                    size="medium"
                    style={{
                      height: 'fit-content',
                    }}
                  >
                    <TableHead 
                      style={{
                        position: 'sticky', 
                        top: 0,
                        backgroundColor: '#fff',
                        zIndex: 1
                      }}
                    >
                      <TableRow>
                        <TableCell>
                          {getTranslation('name')}
                        </TableCell>

                        <TableCell align="center">
                          {getTranslation('mode')}
                        </TableCell>

                        <TableCell align="center">
                          {getTranslation('minRelevance')}
                        </TableCell>

                        <TableCell align="center">
                          {getTranslation('modal.conversationHistory.updatedAt')}
                        </TableCell>

                        <TableCell align="center">
                          Status
                        </TableCell>

                        <TableCell align="center">
                          {getTranslation('actions')}
                        </TableCell>
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {contentGroups.map((contentGroup) => (
                        <TableRow key={contentGroup.id} style={{ height: 'fit-content' }}>
                          <TableCell>{contentGroup.name.length > 16 ? (
                            contentGroup.name.substring(0, 17).concat('...')
                          ) : (
                            contentGroup.name
                          )}
                          </TableCell>

                          <TableCell align="center">
                            {contentGroup.extractive ? (
                              getTranslation('extractive')
                            ) : (
                              getTranslation('generative')
                            )}
                          </TableCell>

                          <TableCell align="center">{contentGroup.score_threshold}</TableCell>

                          <TableCell align="center">
                            {formatDateTime(new Date(contentGroup.updated_at))}
                          </TableCell>

                          <TableCell align="center">
                            <StatusChip
                              label={getTranslation(contentGroup.status.toLocaleLowerCase() as getTranslationKeys)}
                              status={contentGroup.status}
                            />
                          </TableCell>

                          <TableCell align='center'>
                            <Button
                              onClick={(event) => handleOpenActionsMenu(event, contentGroup)}
                              variant='outlined'
                              aria-haspopup="true"
                              aria-controls="customized-menu"
                              style={{
                                padding: '4px 8px',
                                minWidth: 0,
                              }}
                            >
                              <MoreHoriz />
                            </Button>
                            <S.MenuActionsContainer
                              id='customized-menu'
                              open={Boolean(actionsMenuAnchorEl)}
                              onClose={handleCloseActionsMenu}
                              anchorEl={actionsMenuAnchorEl}
                              aria-hidden='false'
                              anchorOrigin={{
                                vertical: 'bottom',
                                horizontal: 'center',
                              }}
                              transformOrigin={{
                                vertical: 'top',
                                horizontal: 'center',
                              }}
                              elevation={1}
                              getContentAnchorEl={null}
                              keepMounted
                            >
                              <MenuItem
                                onClick={() => openAddOrEditModal()}
                              >
                                {getTranslation('edit')}
                              </MenuItem>

                              <MenuItem
                                onClick={() => openAddModal()}
                              >
                                {getTranslation('addDataSource')}
                              </MenuItem>

                              <MenuItem
                                onClick={() => openViewModal()}
                              >
                                {getTranslation('view')}
                              </MenuItem>

                              <Divider />


                              <MenuItem
                                onClick={() => openActionConfirm()}
                              >
                                {getTranslation('delete')}
                              </MenuItem>
                            </S.MenuActionsContainer>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              )}
            </S.ContentContainer>
          )}
        </S.DialogContainer>
      </Dialog>

      {isOpenModalAddOrEditContentGroup && (
        <ModalAddOrEditContentGroup
          setIsOpen={setIsOpenModalAddOrEditContentGroup}
          contentGroupId={contentGroupSelected?.id}
          setRefresh={setRefresh}
        />
      )}

      {isOpenModalDataSourceList && contentGroupSelected && (
        <ModalDataSourceList
          setIsOpen={setIsOpenModalDataSourceList}
          contentGroup={contentGroupSelected}
        />
      )}

      {isOpenModalAddDataSource && contentGroupSelected && (
        <ModalAddDataSource
          setIsOpen={setIsOpenModalAddDataSource}
          contentGroupId={contentGroupSelected.id}
          setIsOpenReturnComponent={setIsOpenModalDataSourceList}
        />
      )}

      {isOpenModalActionConfirm && (
        <ModalActionConfirm
          setIsOpen={setIsOpenModalActionConfirm}
          title={getTranslation('modal.actionConfirm.removeContentGroup.title')}
          subtitle={
            <Trans
              i18nKey='modal.actionConfirm.removeContentGroup.subtitle'
              components={{
                bold: <strong />
              }}
              values={{
                name: contentGroupSelected?.name
              }}
            />
          }
          confirmActionText={contentGroupSelected?.name}
          confirmActionRequest={deleteContentGroup}
        />
      )}
    </>
  );
}
