const STATUS_MAP = {
    Readability: {
        HARD: "<strong>Legibilidade:</strong> O texto está difícil de ler. Simplifique a linguagem.<br/>",
        EASY: "<strong>Legibilidade:</strong> O texto é fácil de ler. Busque enriquecer o conteúdo.<br/>",
        OK: null,
    },
    ReadingLevel: {
        HARD: "<strong>Nível de Leitura:</strong> Texto sofisticado. Simplifique para público amplo.<br/>",
        EASY: "<strong>Nível de Leitura:</strong> Bom para jovens. Adicione complexidade para público maduro.<br/>",
        OK: null,
    },
    Formatting: {
        TOO_MANY: "<strong>Formatação:</strong> Muitas quebras de linha. Una conteúdo similar.<br/>",
        TOO_FEW: "<strong>Formatação:</strong> Adicione quebras de linha para separar assuntos.<br/>",
        OK: null,
    },
    RepetitiveContent: {
        TOO_REPETITIVE: "<strong>Conteúdo Repetitivo:</strong> Há repetições. Mantenha o texto conciso.<br/>",
        REPETITIVE: "<strong>Conteúdo Repetitivo:</strong> Seções repetem ideias. Elimine redundâncias.<br/>",
        OK: null,
    },
};

type StatusCategory = keyof typeof STATUS_MAP;

export function processStatus(statusArray: string[]): string[] {
    const categories: StatusCategory[] = ["Readability", "ReadingLevel", "Formatting", "RepetitiveContent"];
    const messages: string[] = [];

    statusArray.forEach((status, index) => {
        const category = categories[index];
        const message = STATUS_MAP[category]?.[status as keyof typeof STATUS_MAP[StatusCategory]] || '';

        if (message) {
            messages.push(message);
        }
    });

    return messages;
}
